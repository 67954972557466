import React from 'react';

import { wrapper, header, text, button, icon } from './document-card.module.scss';

import useTranslations from '../../hooks/use-translations';
import { IMedia } from '../../models/media.model';
import DownloadIcon from '../../assets/images/svg/download.svg';

import Heading from '../atoms/heading';
import Content from '../atoms/content';

interface IDocumentCardProps {
    document: IMedia;
}

const DocumentCard: React.FC<IDocumentCardProps> = ({ document }) => {
    const t = useTranslations('DocumentCard');

    return (
        <div className={wrapper}>
            <Heading className={header} numberOfStyle={6}>
                {document.alt}
            </Heading>
            <Content numberOfStyle={2} className={text}>
                {document.caption}
            </Content>
            <a href={document.url} className={button} target="_blank" rel="noreferrer noopener">
                <DownloadIcon className={icon} />
                <span>{t.button}</span>
            </a>
        </div>
    );
};

export default DocumentCard;
