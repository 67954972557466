import React, { useState } from 'react';

import { button, active } from './article-scroll-button.module.scss';

import useScroll from '../../hooks/use-scroll';

import ArrowUpIcon from '../../assets/images/svg/arrow-up.svg';
import useTranslations from '../../hooks/use-translations';

interface IArticleScrollButtonProps {
    className?: string;
}

const ArticleScrollButton: React.FC<IArticleScrollButtonProps> = ({ className = '' }) => {
    const t = useTranslations('ArticleScrollButton');
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    useScroll(() => setIsScrolled(window.scrollY > SCROLL_LIMIT));

    return (
        <button
            onClick={handleClick}
            className={`${button} ${className} ${isScrolled ? active : ''}`}
            aria-label={t.label}
        >
            <ArrowUpIcon />
        </button>
    );
};

const SCROLL_LIMIT = 200;

export default ArticleScrollButton;
