// extracted by mini-css-extract-plugin
export var articleWrapper = "post-module--article-wrapper--38397";
export var asideGrid = "post-module--aside-grid--11ca5";
export var border = "post-module--border--f4bee";
export var centerGrid = "post-module--center-grid--9bef5";
export var grid = "post-module--grid--d7a9b";
export var header = "post-module--header--3ef02";
export var headerImage = "post-module--header-image--f2c68";
export var lead = "post-module--lead--2c94c";
export var mainWrapper = "post-module--main-wrapper--8856a";
export var scrollButton = "post-module--scroll-button--3f826";