import React from 'react';

import {
    mainImage,
    markdown,
    ratioClass,
    headingWrapper,
    heading,
    fallbackPrintWidget,
} from './article-topic.module.scss';
import { IPost } from '../../models/post.model';
import { IMedia } from '../../models/media.model';

import Heading from '../atoms/heading';
import Markdown from '../hoc/markdown';
import RatioImage from '../atoms/ratio-image';
import PrintWidget from '../molecules/print-widget';

export interface IArticleTopicProps {
    topic?: IPost;
    fallback: {
        content?: string;
        media?: IMedia[];
        postId: number;
    };
    canPrint?: boolean;
}

export default function ArticleTopic({ topic, fallback, canPrint }: IArticleTopicProps) {
    return (
        <>
            <RatioImage
                className={mainImage}
                ratioClass={ratioClass}
                media={topic?.media && topic.media?.length > 0 ? topic.media : fallback.media}
                relation={1}
                preserveSpace={false}
            />
            {topic && (
                <>
                    <div className={headingWrapper}>
                        <Heading numberOfStyle={3} theme="accent" className={heading}>
                            {topic.title}
                        </Heading>
                        {canPrint && <PrintWidget slug={`posts/${topic.postId}`} />}
                    </div>
                    {topic.content && <Markdown>{topic.content}</Markdown>}
                </>
            )}
            {!topic && canPrint && (
                <PrintWidget className={fallbackPrintWidget} slug={`posts/${fallback.postId}`} />
            )}
            {!topic && fallback?.content && (
                <Markdown className={markdown}>{fallback.content}</Markdown>
            )}
        </>
    );
}
