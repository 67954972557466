import React from 'react';
import { graphql } from 'gatsby';

import {
    border,
    grid,
    mainWrapper,
    asideGrid,
    centerGrid,
    asideWrapper,
    articleWrapper,
    header,
    headerImage,
    lead as leadClass,
    scrollButton,
} from './post.module.scss';
import { IPost, ITopic } from '../models/post.model';
import { getAllMediaWithRelation } from '../utils/get-media-with-relation';
import relations from '../config/relations';
import useMenuPaginationData from '../hooks/use-menu-pagination-data';
import sortBySequence from '../utils/sort-by-sequence';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import DocumentCard from '../components/molecules/document-card';
import AsideNavigation from '../components/molecules/aside-navigation';
import ArticleScrollButton from '../components/atoms/article-scroll-button';
import ArticleTopic from '../components/organisms/article-topic';
import Pagination from '../components/molecules/pagination';
import RatioImage from '../components/atoms/ratio-image';
import Content from '../components/atoms/content';

export interface IArticleProps {
    data: {
        post: IPost;
        topic: ITopic;
    };
}

const Post = ({ data }: IArticleProps) => {
    const {
        title,
        subtitle,
        lead,
        topics,
        content,
        media,
        isHighlighted,
        canPrint,
        pathname,
        postId,
        ornament,
    } = data.post;
    const paginationData = useMenuPaginationData(pathname);

    const topic = data.topic;
    const hasTopics = topics && topics.length > 0;

    const documents = getAllMediaWithRelation(media || [], relations.document);

    return (
        <MainLayout
            className={grid}
            breadcrumbsClassName={isHighlighted ? border : ''}
            hasOrnament={ornament}
        >
            <div className={mainWrapper}>
                <div className={`${hasTopics ? asideGrid : centerGrid}`}>
                    {hasTopics && (
                        <div className={asideWrapper}>
                            <AsideNavigation topics={topics.sort(sortBySequence())} />
                        </div>
                    )}
                    <div className={articleWrapper}>
                        <Content numberOfStyle={2}>{subtitle}</Content>
                        <div className={header}>
                            <Heading numberOfStyle={2}>{title}</Heading>
                            <RatioImage
                                className={headerImage}
                                media={media}
                                relation={relations.articleHeadingImage}
                                useFallback={false}
                            />
                        </div>
                        <Heading className={leadClass} numberOfStyle={5} isRegular>
                            {lead}
                        </Heading>
                        <ArticleTopic
                            topic={topic}
                            fallback={{ media, content, postId }}
                            canPrint={canPrint}
                        />
                        {documents.map((document) => (
                            <DocumentCard
                                key={`document-card-${document.fileId}`}
                                document={document}
                            />
                        ))}
                        <Pagination as="page" {...paginationData} />
                    </div>
                    <ArticleScrollButton className={scrollButton} />
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($postId: Int, $topicId: Int) {
        post(postId: { eq: $postId }) {
            ...postFragment
        }
        topic(postId: { eq: $topicId }) {
            ...topicFragment
        }
    }
`;

export default Post;
