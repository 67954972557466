import React from 'react';

import { wrapper, header, list, link as linkClass, active } from './aside-navigation.module.scss';
import { ITopic } from '../../models/post.model';
import useTranslations from '../../hooks/use-translations';

import NavLink from '../atoms/nav-link';
import Content from '../atoms/content';

interface IAsideNavigationProps {
    topics: ITopic[];
}

const AsideNavigation: React.FC<IAsideNavigationProps> = ({ topics }) => {
    const t = useTranslations('AsideNavigation');

    return (
        <nav className={wrapper}>
            <Content numberOfStyle={2} className={header}>
                {t.content}
            </Content>
            <ul className={list}>
                {topics.map(({ pathname, title, postId }) => {
                    if (!title) return null;
                    return (
                        <li key={`topic-nav-link-${postId}`}>
                            <NavLink
                                activeClassName={active}
                                className={linkClass}
                                navLink={{ label: title, pathname }}
                            />
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default AsideNavigation;
